import {KeyValuePipe} from '@angular/common'
import {Component, inject, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatCard} from '@angular/material/card'
import {MatIcon} from '@angular/material/icon'
import {MatProgressSpinner} from '@angular/material/progress-spinner'
import {ActivatedRoute, ParamMap, Router, RouterLink} from '@angular/router'
import {IRecipe, TDailyMeal, TDay} from '@ellen/user-be'
import {first} from 'rxjs'
import {
  INTERNAL_MODULE,
  MY_COOKBOOK_ROUTE,
  ROUTE_PARAM_DAY,
  ROUTE_PARAM_MEAL
} from '../../../application/constants'
import {RecipesService} from '../../../services/recipes.service'
import {AppAddsComponent} from '../../common/app-adds/app-adds.component'
import {RecipeContentComponent} from './recipe-content/recipe-content.component'

@Component({
  selector: 'eln-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss'],
  imports: [
    RouterLink,
    MatButton,
    KeyValuePipe,
    MatProgressSpinner,
    MatIcon,
    RecipeContentComponent,
    MatCard,
    AppAddsComponent
  ],
  standalone: true
})
export class RecipeComponent implements OnInit {

  public recipe: IRecipe | null = null

  protected recipesService: RecipesService = inject(RecipesService)
  private route: ActivatedRoute = inject(ActivatedRoute)
  private router: Router = inject(Router)

  // We save parameters received in route to use later
  private day!: TDay
  private meal!: TDailyMeal

  public ngOnInit() {
    // Get recipe to be shown
    this.route.paramMap
      .pipe(first())
      .subscribe((params) => {
        this.loadRecipeFromFamilySchedule(params)
      })
  }

  public onDiscardRecipeClick() {
    // Remove recipe and send user to "MyCookbook"
    this.recipesService.removeFamilyRecipe(this.day, this.meal)
      .subscribe(() => this.navigateToCookbook())
  }

  private loadRecipeFromFamilySchedule(params: ParamMap) {
    // This screen have two params when it is not being shown after a menu
    // generation, day and meal, which refer to a recipe within
    // family's schedule
    this.day = params.get(ROUTE_PARAM_DAY)! as TDay
    this.meal = params.get(ROUTE_PARAM_MEAL)! as TDailyMeal

    // Check if loaded recipe in recipes service is the one we want.
    // If not, we'll need to recover it
    this.recipesService.getRecipe(this.day, this.meal)
      .subscribe((recipe) => {
        // If recipe was not found, send user back to "My Cookbook"
        if (!recipe) {
          this.navigateToCookbook()
          return
        }

        this.recipe = recipe
      })
  }

  private navigateToCookbook() {
    this.router
      .navigate(['/', INTERNAL_MODULE, MY_COOKBOOK_ROUTE])
      .then()
  }
}
