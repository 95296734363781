import {ElementRef, signal, Signal, WritableSignal} from '@angular/core'
import {BehaviorSubject, filter, Observable, Subscription} from 'rxjs'

export class CarouselUtils {
  public activeChange$: Observable<number>

  public readonly pageSize: number = 0
  public readonly offset: number = 0
  public isLeftArrowActive: WritableSignal<boolean> = signal(false)
  public isRightArrowActive: WritableSignal<boolean> = signal(false)

  private pActiveChange$: BehaviorSubject<number> =
    new BehaviorSubject<number>(-1)
  private cancelChangeEvents: boolean = false
  private pCurrentPage: number = 0
  private pTotalPages: number = 0

  private readonly holder!: Signal<ElementRef<HTMLDivElement>>
  private readonly items!: Signal<readonly ElementRef<HTMLDivElement>[]>
  private readonly numbers!: Signal<readonly ElementRef<HTMLDivElement>[]>

  private sub$: Subscription = new Subscription()

  constructor(
    holder: Signal<ElementRef<HTMLDivElement> | undefined>,
    items: Signal<readonly ElementRef<HTMLDivElement>[] | undefined>,
    numbers: Signal<readonly ElementRef<HTMLDivElement>[] | undefined>,
    pageSize: number,
    offset: number = 0
  ) {
    if (!holder() || !items() || !numbers()) {
      throw Error('Make sure to setup Carousel in ngAfterViewInit')
    }

    // Setup observables
    this.activeChange$ = this.pActiveChange$.asObservable()
      .pipe(
        filter(() => !this.cancelChangeEvents),
        filter((val) => val !== -1)
      )

    this.holder = holder as Signal<ElementRef<HTMLDivElement>>
    this.items = items as Signal<readonly ElementRef<HTMLDivElement>[]>
    this.numbers = numbers as Signal<readonly ElementRef<HTMLDivElement>[]>
    this.pageSize = pageSize
    this.offset = offset

    // Initialise behaviours
    this.initialise()
  }

  public get activeNumber(): number {
    return this.pActiveChange$.value
  }

  public get currentPage(): number {
    return this.pCurrentPage
  }

  /**
   * Function that should be called when destroying component that uses
   * CarouselUtils. Otherwise, we will end up with some memory leaks.
   */
  public destroy() {
    this.sub$.unsubscribe()
  }

  /**
   * Function that indicates if passed index is visible or hidden considering
   * the carousel's pagination
   */
  public isNumberHidden(index: number): boolean {
    const indexPage = Math.floor(index / this.pageSize)
    return indexPage !== this.currentPage
  }

  /**
   * Function that will change current page to previous page.
   */
  public previousPage(): void {
    this.pCurrentPage--
    this.setArrowFlags()
  }

  /**
   * Function that will change current page to next page.
   */
  public nextPage(): void {
    this.pCurrentPage++
    this.setArrowFlags()
  }

  /**
   * Function that needs to be called whenever an item is added/removed to/from
   * carousel items.
   */
  public update() {
    // We basically re-apply all initial configurations,
    // waiting a millisecond to allow UI to paint new added item.
    setTimeout(() => {
      this.initialise()
    }, 1)
  }

  /**
   * Function that will move the carousel to a certain spot given an index.
   * The navigation to this point can be instant or smoothly.
   * @param index Index of item to navigate to.
   * @param instant Flag that if true it will make the navigation instant. If
   * not, tha navigation will be smooth.
   */
  public navigateToItemByIndex(index: number, instant: boolean = false) {
    // Start navigation
    this.items()[index].nativeElement.scrollIntoView({
      behavior: instant ? 'instant' : 'smooth',
      block: 'end'
    })
  }

  /**
   * Function that will move carousel to last item's position.
   */
  public goToLastItem() {
    // If there are no items it will do nothing
    if (this.items().length === 0) {
      return
    }

    // We wait a millisecond to allow UI to paint new added item.
    setTimeout(() => {
      this.navigateToItemByIndex(this.items().length - 1)
    }, 1)
  }

  private initialise() {
    // Initialise behaviours
    this.setUpScrollingBehaviour()
    this.setUpGoToItemOnClickBehaviour()
    this.setUpPaginationListener()
    // Set initial active number
    this.setActiveNumber()
  }

  private setUpPaginationListener() {
    // First things first, we calculate the amount of pages we have
    this.pTotalPages = Math.ceil((this.offset + this.items().length) / this.pageSize) - 1

    this.sub$ = this.activeChange$.subscribe(() => {
      this.pCurrentPage = Math.floor((this.offset + this.activeNumber) / this.pageSize)
      this.setArrowFlags()
    })
  }

  private setArrowFlags() {
    // We will activate left/right arrows depending on current page and
    // number of total items.
    // Right arrow is always active as long as we are not in final page
    this.isRightArrowActive.set(this.currentPage < this.pTotalPages)
    // Left arrow is always active as long as we are not in first page
    this.isLeftArrowActive.set(this.currentPage > 0)
  }

  private setUpScrollingBehaviour() {
    this.holder().nativeElement.onscroll = () => {
      this.setActiveNumber()
    }
  }

  private setUpGoToItemOnClickBehaviour() {
    this.numbers().forEach((number, index) => {
      number.nativeElement.onclick = () => {
        this.navigateToItemByIndex(index)
      }
      number.nativeElement.style.cursor = 'pointer'
    })
  }

  private setActiveNumber() {
    // If all items/numbers have been removed, active number is -1
    if (this.numbers().length === 0 || this.items().length === 0) {
      this.pActiveChange$.next(-1)
      return
    }

    // If there is only one element, we don't need calculations
    if (this.numbers().length === 1) {
      // Save active number. It might be useful.
      this.pActiveChange$.next(0)
      // Add "active" class to the only active element, the first one.
      this.numbers()[0].nativeElement.classList.add('active')
      return
    }

    const holderX = this.holder().nativeElement.getBoundingClientRect().x
    const itemWidth = this.items()[0].nativeElement.offsetWidth
    const distanceBetweenItemX = this.items()[1].nativeElement.getBoundingClientRect().x
      - this.items()[0].nativeElement.getBoundingClientRect().x
    const itemGap = distanceBetweenItemX - itemWidth

    /* Active card is the first element with its "x" position between Pn & Pn+1.
     This examples shows the box structure when active item is Item2.
     Item1 & Item3 will be invisible since they are outside of holder.
      ┌──────────────────── Scroll ───────────────────┐
      │               ┌──── Holder ───┐               │
      │            HolderX            │               │
      │             P(n)           P(n+1)             │
      │  ┌──Item1──┐  │  ┌──Item2──┐  │  ┌──Item3──┐  │
      │  │         │  │  │         │  │  │         │  │
      │  X1        │  │  X2        │  │  X3        │  │
      │  │         │  │  │         │  │  │         │  │
      │  └─────────┘  │  └─────────┘  │  └─────────┘  │
      │               └───────────────┘               │
      └───────────────────────────────────────────────┘
    */
    const index = this.items()
      .findIndex(item => {
        const itemX = item.nativeElement.getBoundingClientRect().x
        return itemX >= (holderX - (itemWidth + itemGap) / 2) &&
          itemX <= (holderX + (itemWidth + itemGap) / 2)
      })
    // Remove "active" class from all elements
    this.numbers().forEach(el =>
      el.nativeElement.classList.remove('active'))
    // Save active number. It might be useful.
    this.pActiveChange$.next(index)
    // Add "active" class to the only active element
    this.numbers()[index].nativeElement.classList.add('active')
  }
}
