<mat-card class="menu-day-config-holder">
  <span class="menu-day-title">
    {{ menuDayConfig().day | date:'EEEE d/M':undefined:'sv' | titlecase }}
  </span>

  <!-- When all meals are inactive, it will show a little tag -->
  @if (menuDayConfig().allInactive) {
    <div class="menu-day-inactive-tag">Inaktiv</div>
  }

  @for (meal of DailyMeals; track meal) {
    <div class="menu-day-meal-holder">
      <div class="menu-day-meal-title-holder">
        <span>{{ meal | dailyMeal }}</span>
        <button [class.inactive]="!menuDayConfig()[meal].active"
                (click)="toggleMealActive(meal)">
          {{ menuDayConfig()[meal].active ? 'Aktiv' : 'Aktivera' }}
        </button>
      </div>

      <mat-divider class="meal-divider"></mat-divider>

      <div class="menu-day-meal-config-holder"
           [ngClass]="menuDayConfig()[meal].active ? 'active' : 'inactive'">
        <div class="menu-meal-qualities">
          @for (quality of MenuQualities; track quality) {
            <button mat-button
                    (click)="toggleQuality(menuDayConfig()[meal], quality)"
                    [class.inactive]="menuDayConfig()[meal].quality !== quality"
                    [style.background-color]="quality | menuQuality:'color'"
            >{{ quality | menuQuality }}
            </button>
          }
        </div>

        <mat-divider></mat-divider>

        <div class="menu-meal-family-members-holder">
          @for (user of userService.familyMembers$(); track user) {
            <div class="menu-meal-family-member-holder">
              <eln-avatar
                [image]="user.avatarUrl"
                [size]="45"
                [disabled]="!menuDayConfig()[meal].familyMembers.includes(user.id)"
                [selected]="menuDayConfig()[meal].familyMembers.includes(user.id)"
                (keydown.enter)="toggleFamilyMember(menuDayConfig()[meal], user.id)"
                (click)="toggleFamilyMember(menuDayConfig()[meal], user.id)"
              ></eln-avatar>
              <span>{{ (user.name | split)[0] }}</span>
            </div>
          }
        </div>

        <mat-divider></mat-divider>

        <div class="menu-meal-guests-holder">
          <span>Gäster</span>

          <div class="menu-meal-guests-config-holder">
            <button class="menu-meal-guests-remove"
                    (click)="removeGuest(menuDayConfig()[meal])">
              <mat-icon>remove</mat-icon>
            </button>
            <span>{{ menuDayConfig()[meal].guests }}</span>
            <button class="menu-meal-guests-add"
                    (click)="addGuest(menuDayConfig()[meal])">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  }
</mat-card>