import {inject} from '@angular/core'
import {map, Observable} from 'rxjs'
import {ConfigService} from '../services/config.service'

export const authGuard = (): Observable<boolean> => {
  const configService: ConfigService = inject(ConfigService)
  // In other cases it is a good idea to route to other places
  // instead of just say yes or no.
  return configService.accessToken$
    .pipe(map(value => !!value))
}
