import {Injectable, signal} from '@angular/core'
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class DreamService {
  private url = environment.webSocketUrl
  private socket: WebSocket
  public messages$ = signal<{
    sender: string,
    text: string,
    complete: boolean
  }[]>([{
    text: 'Hej! Vad vill du laga idag?',
    sender: 'assistant',
    complete: true
  }])


  constructor() {
    this.socket = new WebSocket(this.url)
    this.socket.onopen = () => {
    }

    this.socket.onmessage = (event: { data: string }) => {
      const data = JSON.parse(event.data)
      this.handleMessage(data.message)
    }

    this.socket.onerror = (error: any) => {
      console.error('WebSocket error:', error)
    }
  }

  public sendMessage(prompt: string) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({prompt, action: 'CHAT'})) //TODO: CHECK!!!!
      const newMessages = [...this.messages$(), {
        sender: 'Du',
        text: prompt,
        complete: true
      }]
      this.messages$.set(newMessages)
    } else {
      console.error('WebSocket connection is not open.')
    }
  }

  public closeConnection() {
    this.socket.close()
  }

  public handleMessage(message: string) {
    const currentMessages = this.messages$()
    const lastMessage = currentMessages[currentMessages.length - 1]

    if (lastMessage && !lastMessage.complete && lastMessage.sender === 'Din assistent') {
      lastMessage.text += message
    } else {
      currentMessages.push({
        sender: 'Din assistent',
        text: message,
        complete: false
      })
    }

    this.messages$.set([...currentMessages])
  }
}