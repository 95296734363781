<eln-adds></eln-adds>

<div class="dream-machine-holder" #chatWindow>
  @if (!(dreamService.messages$().length > 0)) {
    <div class="dream-machine-loading-holder">
      <mat-spinner></mat-spinner>
    </div>
  }

  @for (message of dreamService.messages$(); track $index) {
    <div class="dream-machine-message-holder"
         [ngClass]="{'user-message': message.sender === 'Du', 'assistant-message': message.sender !== 'Du'}">
      <div class="message">
        {{ message.text }}
      </div>
      <div class="arrow"></div>
      <div class="outer-arrow"></div>
    </div>
  }
</div>

<div class="dream-machine-input-outer-holder">
  <div class="dream-machine-input-inner-holder">
    <mat-form-field appearance="outline" subscriptSizing="dynamic"
                    class="dream-machine-input">
        <textarea
          class="dream-machine-input-textarea"
          placeholder="Skriv här..."
          [formControl]="userInput"
          [maxlength]="maxTextLength"
          (keyup.enter)="sendMessage()"
          matInput
        ></textarea>

      <mat-icon matIconSuffix
                (click)="sendMessage()"
                (keydown.enter)="sendMessage()"
                class="dream-machine-input-send"
                [class.active]="userInput.value?.length"
      >send
      </mat-icon>
    </mat-form-field>

    <div class="dream-machine-input-count">
      {{ userInput.value?.length ?? '0' }}/{{ maxTextLength }}
    </div>
  </div>
</div>