import {Component, inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {Router} from '@angular/router'
import {IMenuDayConfig, TDailyMeal} from '@ellen/user-be'
import {
  CREATE_MENU_ROUTE,
  CREATE_MENU_SUMMARY_ROUTE,
  INTERNAL_MODULE
} from '../../../application/constants'
import {RecipesService} from '../../../services/recipes.service'
import {UserService} from '../../../services/user.service'
import {AppAddsComponent} from '../../common/app-adds/app-adds.component'
import {GptLoaderComponent} from '../../common/gpt-loader/gpt-loader.component'
import {
  CreateMenuWarningDialogComponent
} from '../create-menu/create-menu-warning-dialog/create-menu-warning-dialog.component'

@Component({
  selector: 'eln-create-quick-menu',
  standalone: true,
  imports: [
    MatButton,
    GptLoaderComponent,
    AppAddsComponent
  ],
  templateUrl: './create-quick-menu.component.html',
  styleUrl: './create-quick-menu.component.scss'
})
export class CreateQuickMenuComponent {

  public userService: UserService = inject(UserService)
  public recipesService: RecipesService = inject(RecipesService)

  private dialog: MatDialog = inject(MatDialog)
  private router: Router = inject(Router)

  public onMealClick(meal: TDailyMeal) {
    // First we check that all family members are valid. If not, we open a
    // dialog
    if (this.userService.familyMembers$().some(u => !u.isValid)) {
      CreateMenuWarningDialogComponent.open(this.dialog)
      return
    }

    this.recipesService.getMenu(this.userService.familyMembers$(),
      [this.generateAutomaticMeal(meal)])
      .subscribe(() =>
        // Navigate to create menu summary screen after receiving menus.
        this.router
          .navigate(['/', INTERNAL_MODULE, CREATE_MENU_ROUTE, CREATE_MENU_SUMMARY_ROUTE])
          .then()
      )
  }

  private generateAutomaticMeal(activeMeal: TDailyMeal): IMenuDayConfig {
    return {
      day: 'quick',
      [activeMeal]: {
        familyMembers: this.userService.familyMembers$().map(u => u.id),
        guests: 0
      }
    }
  }
}
