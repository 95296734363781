import {registerLocaleData} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeSvExtra from '@angular/common/locales/extra/sv'
import localeFr from '@angular/common/locales/fr'
import localeSv from '@angular/common/locales/sv'
import {
  APP_INITIALIZER,
  ApplicationConfig,
  provideZoneChangeDetection
} from '@angular/core'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling
} from '@angular/router'
import {routes} from './app.routes'
import {authInterceptor} from './application/auth.interceptor'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'
import {responseInterceptor} from './application/response.interceptor'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {ConfigService} from './services/config.service'
import {UserService} from './services/user.service'

registerLocaleData(localeFr, 'fr', localeFrExtra)
registerLocaleData(localeSv, 'sv', localeSvExtra)

export const appConfig: ApplicationConfig = {
  providers: [
    LOCAL_STORAGE_PROVIDERS,
    WINDOW_PROVIDERS,
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService, UserService],
      multi: true
    },
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes, withHashLocation(), withInMemoryScrolling({anchorScrolling: 'enabled'})),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        responseInterceptor
      ])
    )
  ]
}
