import {KeyValuePipe, NgClass} from '@angular/common'
import {Component, inject, input, OnInit, output} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatCard} from '@angular/material/card'
import {MatDialog} from '@angular/material/dialog'
import {MatDivider} from '@angular/material/divider'
import {MatIcon} from '@angular/material/icon'
import {MatSnackBar} from '@angular/material/snack-bar'
import {
  IRecipe,
  IShoppingListItem,
  TDailyMeal,
  TMenuQuality
} from '@ellen/user-be'
import {filter, first} from 'rxjs'
import {MenuQualityPipe} from '../../../../pipes/menu-quality.pipe'
import {SplitPipe} from '../../../../pipes/split.pipe'
import {RecipesService} from '../../../../services/recipes.service'
import {UserService} from '../../../../services/user.service'
import {AvatarComponent} from '../../../common/avatar/avatar.component'
import {
  ConfirmationDialogComponent
} from '../../../common/confirmation-dialog/confirmation-dialog.component'
import {MealTagComponent} from '../../../common/meal-tag/meal-tag.component'

@Component({
  selector: 'eln-recipe-content',
  templateUrl: './recipe-content.component.html',
  styleUrls: ['./recipe-content.component.scss'],
  imports: [
    KeyValuePipe,
    MatIcon,
    NgClass,
    MatCard,
    MealTagComponent,
    AvatarComponent,
    MatButton,
    MatDivider,
    MenuQualityPipe,
    SplitPipe
  ],
  standalone: true
})
export class RecipeContentComponent implements OnInit {

  public title = input.required<string>()
  public meal = input.required<TDailyMeal>()
  public familyMembers = input.required<string[]>()
  public guests = input.required<number>()
  public quality = input<TMenuQuality>()
  public image = input<string>()
  public sections = input<Record<string, string[]>>()

  public canDelete = input<boolean>(false)
  public canAddToCart = input<boolean>(false)
  public onDelete = input<() => void>()

  public select = output<void>()
  public delete = output<void>()

  protected ingredientsToShop: IShoppingListItem[] = []
  protected isReversed: boolean = false
  protected isFlipping: boolean = false
  protected itemsAddedToCart: boolean = false

  protected userService: UserService = inject(UserService)
  private matDialog: MatDialog = inject(MatDialog)
  private matSnackbar: MatSnackBar = inject(MatSnackBar)

  public ngOnInit() {
    // Get all ingredients to shop from sections (we create a fake IRecipe)
    this.ingredientsToShop = !this.sections() ? [] :
      RecipesService.getIngredientsToBuyFromRecipe(
        {sections: this.sections()} as IRecipe)
  }

  public sorting = () => 0

  public onReverseClick() {
    if (this.isFlipping) {
      setTimeout(() => {
        this.isFlipping = false
      }, 300) // This is to fix the scroll and animation at the same time.
      // Match this with scss transition time, this i
    } else {
      this.isFlipping = true
    }
    this.isReversed = !this.isReversed
  }

  public onAddToCartClick() {
    ConfirmationDialogComponent.open(this.matDialog, {
      title: 'Vill du lägga till alla ingredienser i inköpslistan?',
      okButton: 'Ja!'
    })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {
        // It will add all ingredient items to family shopping list
        this.userService.family$
          .pipe(filter(Boolean), first())
          .subscribe(family => {
            family.shoppingList.push(...this.ingredientsToShop)

            this.userService.saveFamilyShoppingList(family.shoppingList)
              .subscribe(() => {
                this.itemsAddedToCart = true
                this.matSnackbar.open(
                  'Tillagd i inköpslistan',
                  'Okej!',
                  {duration: 1500}
                )
              })
          })
      })
  }
}
